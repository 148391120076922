@import url(https://fonts.googleapis.com/css?family=Space+Mono:400,700);
:-webkit-any(article, aside, nav, section) h1 {
  -webkit-margin-before: 0em;
          margin-block-start: 0em;
  -webkit-margin-after: 0em;
          margin-block-end: 0em;
}

body {
  font-family: Space Mono;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  margin: 0;
}

* {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-margin-before: 0em;
          margin-block-start: 0em;
  -webkit-margin-after: 0em;
          margin-block-end: 0em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
}

h1 {
  font-size: 30vmin;
  font-weight: 400;
}

h1.small-text {
  font-size: 20vmin;
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-height: 100vh; */
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  text-align: center;
  -webkit-transition: background-color 0.6s ease;
  transition: background-color 0.6s ease;
}

section section {
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  -webkit-transition: all 1.2s cubic-bezier(0.8, 0.01, 0.2, 1);
  transition: all 1.2s cubic-bezier(0.8, 0.01, 0.2, 1);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transform: scaleY(0) !important;
          transform: scaleY(0) !important;
  -webkit-transform-origin: top;
          transform-origin: top;
  z-index: -1;
  opacity: 1 !important;
}

.page.loaded section section {
  -webkit-transform: scaleY(1) !important;
          transform: scaleY(1) !important;
}

.page section > * {
  opacity: 0;
  -webkit-transform: translateY(60px);
          transform: translateY(60px);
  -webkit-transition: all 1.2s ease;
  transition: all 1.2s ease;
}

.page.loaded section > * {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

section > * {
  position: relative;
  z-index: 3;
}

section.convert-peso,
section.convert-peso-GBP,
section.convert-peso-EUR,
section.convert-peso-USD {
  background-color: #fff !important;
}

section section.convert-USD {
  background-color: rgb(21, 162, 113);
}

section.convert-peso-USD *,
section.convert-peso-USD *::-webkit-input-placeholder,
section.convert-peso-USD .symbol {
  color: rgb(21, 162, 113) !important;
}

section.convert-peso-USD *,
section.convert-peso-USD *::-moz-placeholder,
section.convert-peso-USD .symbol {
  color: rgb(21, 162, 113) !important;
}

section.convert-peso-USD *,
section.convert-peso-USD *:-ms-input-placeholder,
section.convert-peso-USD .symbol {
  color: rgb(21, 162, 113) !important;
}

section.convert-peso-USD *,
section.convert-peso-USD *::-ms-input-placeholder,
section.convert-peso-USD .symbol {
  color: rgb(21, 162, 113) !important;
}

section.convert-peso-USD *,
section.convert-peso-USD *::placeholder,
section.convert-peso-USD .symbol {
  color: rgb(21, 162, 113) !important;
}

section.convert-peso-USD input {
  border-bottom: 1px solid rgb(21, 162, 113) !important;
}

section section.convert-EUR {
  background-color: rgb(105, 27, 73);
}

section.convert-peso-EUR *,
section.convert-peso-EUR *::-webkit-input-placeholder,
section.convert-peso-EUR .symbol {
  color: rgb(105, 27, 73) !important;
}

section.convert-peso-EUR *,
section.convert-peso-EUR *::-moz-placeholder,
section.convert-peso-EUR .symbol {
  color: rgb(105, 27, 73) !important;
}

section.convert-peso-EUR *,
section.convert-peso-EUR *:-ms-input-placeholder,
section.convert-peso-EUR .symbol {
  color: rgb(105, 27, 73) !important;
}

section.convert-peso-EUR *,
section.convert-peso-EUR *::-ms-input-placeholder,
section.convert-peso-EUR .symbol {
  color: rgb(105, 27, 73) !important;
}

section.convert-peso-EUR *,
section.convert-peso-EUR *::placeholder,
section.convert-peso-EUR .symbol {
  color: rgb(105, 27, 73) !important;
}

section.convert-peso-EUR input {
  border-bottom: 1px solid rgb(105, 27, 73) !important;
}

section section.convert-GBP {
  background-color: rgb(0, 77, 170);
}

section.convert-peso-GBP *,
section.convert-peso-GBP *::-webkit-input-placeholder,
section.convert-peso-GBP .symbol {
  color: #0074d9 !important;
}

section.convert-peso-GBP *,
section.convert-peso-GBP *::-moz-placeholder,
section.convert-peso-GBP .symbol {
  color: #0074d9 !important;
}

section.convert-peso-GBP *,
section.convert-peso-GBP *:-ms-input-placeholder,
section.convert-peso-GBP .symbol {
  color: #0074d9 !important;
}

section.convert-peso-GBP *,
section.convert-peso-GBP *::-ms-input-placeholder,
section.convert-peso-GBP .symbol {
  color: #0074d9 !important;
}

section.convert-peso-GBP *,
section.convert-peso-GBP *::placeholder,
section.convert-peso-GBP .symbol {
  color: #0074d9 !important;
}

section.convert-peso-GBP input {
  border-bottom: 1px solid #0074d9 !important;
}

input[type='number'] {
  margin-top: 40px;
  background-color: unset;
  border: 0px;
  border-bottom: 1px solid #fff;
  color: #fff;
  outline: unset;
  width: 30vw;
  text-align: center;
  font-family: Space Mono;
  font-size: 16px;
}

input[type='number']::-webkit-input-placeholder {
  color: #fff;
  text-align: center;
  font-family: Space Mono;
}

input[type='number']::-moz-placeholder {
  color: #fff;
  text-align: center;
  font-family: Space Mono;
}

input[type='number']:-ms-input-placeholder {
  color: #fff;
  text-align: center;
  font-family: Space Mono;
}

input[type='number']::-ms-input-placeholder {
  color: #fff;
  text-align: center;
  font-family: Space Mono;
}

input[type='number']::placeholder {
  color: #fff;
  text-align: center;
  font-family: Space Mono;
}

input.convert-peso[type='number']::-webkit-input-placeholder {
  color: #0074d9;
  text-align: center;
  font-family: Space Mono;
}

input.convert-peso[type='number']::-moz-placeholder {
  color: #0074d9;
  text-align: center;
  font-family: Space Mono;
}

input.convert-peso[type='number']:-ms-input-placeholder {
  color: #0074d9;
  text-align: center;
  font-family: Space Mono;
}

input.convert-peso[type='number']::-ms-input-placeholder {
  color: #0074d9;
  text-align: center;
  font-family: Space Mono;
}

input.convert-peso[type='number']::placeholder {
  color: #0074d9;
  text-align: center;
  font-family: Space Mono;
}

nav {
  position: fixed;
  top: 50px;
  left: 50%;
  -webkit-transform: translateX(-50%) !important;
          transform: translateX(-50%) !important;
}

p {
  -webkit-margin-before: 0em;
          margin-block-start: 0em;
  -webkit-margin-after: 0em;
          margin-block-end: 0em;
}

nav p {
  color: #fff;
  text-decoration: none;
  display: inline-flex;
  cursor: pointer;
  -webkit-transition: all 1.2s ease;
  transition: all 1.2s ease;
}

nav p.selected {
  text-decoration: underline;
}

p.middle-a {
  margin-left: 15px;
  margin-right: 15px;
}

p.convert {
  position: fixed;
  top: 0;
  left: 50%;
  border-radius: 2px;
  margin-top: 20px;
  -webkit-transform: translateX(-50%) !important;
          transform: translateX(-50%) !important;
  cursor: pointer;
}

p.convert a,
nav p {
  color: #fff;
  text-decoration: none !important;
  position: relative;
}

p.convert a::after,
nav p::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  content: '';
  background: #fff;
}

p.convert a:hover::after,
nav p:hover::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

p.marco-polo {
  top: unset;
  bottom: 0;
  margin-top: unset;
  margin-bottom: 20px;
}

.symbol {
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
  opacity: 0.1 !important;
  color: #fff;
  padding-bottom: 60px;
  font-size: 60vw;
}

@media (max-width: 480px) {
  input[type='number'] {
    width: 80vw;
    border-radius: 0;
  }

  h1 {
    font-size: 82px;
  }

  p {
    text-align: center;
    font-size: 14px;
  }

  .symbol {
    font-size: 150vw;
  }
}

